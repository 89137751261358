export default [
    {
        path: '/orderManage',
        name: 'orderManage',
        meta: {
            title: '订单管理'
        },
        component: r => require.ensure([], () => r(require('../pages/order/orderManage/')), 'orderManage')
    },
    {
        path: '/asDriver/:orderId/:driverInfo/:sourceType',
        name: 'assignDriver',
        meta: {
            title: '分配司导'
        },
        component: r => require.ensure([], () => r(require('../pages/order/assignDriver/')), 'assignDriver')
    },
    {
        path: '/orDetail/:orderId',
        name: 'orderDetails',  //订单详情
        meta: {
            title: '订单详情'
        },
        component: r => require.ensure([], () => r(require('../pages/order/orderDetails/')), 'orderDetails')
    },
    {
        path: '/dispatchGrab/:orderId/:name',
        name: 'dispatchGrab',  //订单详情
        meta: {
            title: '分派抢单'
        },
        component: r => require.ensure([], () => r(require('../pages/order/dispatchGrab/')), 'order')
    },
    {
        path: '/distSupplier/:orderId/:operatorId/:orderStatus',
        name: 'distSupplier',  //订单详情
        meta: {
            title: '分配供应商'
        },
        component: r => require.ensure([], () => r(require('../pages/order/distributionSupplier/')), 'order')
    },
    {
        path: '/brandModel',
        name: 'brandModel',  //订单详情
        meta: {
            title: '选择车辆品牌'
        },
        component: r => require.ensure([], () => r(require('../components/brandModel/')), 'order')
    },
    {
        path: '/cancelOrder/:orderId/:refundAmount/:paymentAmount/:actualSettleAmount',
        name: 'cancelOrder',  //订单详情
        meta: {
            title: '取消订单'
        },
        component: r => require.ensure([], () => r(require('../pages/order/cancelOrder/')), 'order')
    },
    {
        path: '/demandOrder',
        name: 'demandOrder',
        meta: {
            title: '客服下单'
        },
        component: r => require.ensure([], () => r(require('../pages/order/demandOrder/')), 'order')
    },
    {
        path: '/tripOrderList',
        name: 'tripOrderList',
        meta: {
            title: '旅游用车订单'
        },
        component: r => require.ensure([], () => r(require('../pages/order/tripOrderList/')), 'order')
    },
    {
        path: '/orderNotes/:orderId/:orderNo',
        name: 'orderNotes',
        meta: {
            title: '订单备注'
        },
        component: r => require.ensure([], () => r(require('../pages/order/orderNotes/')), 'order')
    },
    {
        path: '/orderLog/:orderId',
        name: 'orderLog',
        meta: {
            title: '订单日志'
        },
        component: r => require.ensure([], () => r(require('../pages/order/orderLog/')), 'order')
    }
]
