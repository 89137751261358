/*
根据陈寅搭建后台系统 统一的http请求
 */
import axios from 'axios'
import conf from '../../config/'
import {clearUserLoginInfo} from "./user";
import Toast from './toast'
import store from '../../www/vuex'

const axiosConfig = (url, form = {}, method = 'GET', responseType = "json") => {
    if (url.indexOf('http') == -1) url = conf.api_url + url;
    let config = {
        method: method,
        url: url,
        headers: {
            "Content-Type": "application/json"
        },
        data: form,
        withCredentials: false,
        responseType: responseType,
    }
    //设置请求头
    const token = store.state.eTripToken
    const info = store.state.loginUserInfo;
    const user = info.loginuserDto || {};
    const port = info.loginPort || {};
    token && (config.headers["Header-Token"] = token);
    port && (config.headers["Header-LoginPort"] = port);
    user && user.id && (config.headers["Header-UserId"] = user.id);
    user && user.loginName && (config.headers["Header-UserName"] = encodeURI(user.loginName));
    return config;
}

const notLogin = () => {
    clearUserLoginInfo(1);
    window.document.href = '#/login'
}

export default async function (url, form = {}, method = 'GET', showError = true, serveName, responseType = "json") {
    if (method === 'GET') {
        if (form) {
            url += '?';
            for (let key in form) {
                url = url + key + "=" + form[key] + '&';
            }
        }
    }
    const config = axiosConfig(url, form, method, responseType);
    try {
        console.log(config);
        const result = await axios(config);
        if (result.status === 200) {
            const data = result.data;
            if (data && !data.success && data.errors && data.errors.length > 0) {
                if (showError) {
                    Toast(data.errors[0].message);
                }
                //未登录
                if (data.errors[0].code === "60022") {
                    notLogin()
                }
            }
            return data;
        }
        else
            return {
                code: -2,
                errors: [
                    {
                        message: '请求出错'
                    }
                ]
            }
    }
    catch (err) {
        const error = '"抱歉，服务繁忙，请稍后重试！"'
        Toast(error);
        return {
            code: -1,
            errors: [
                {
                    message: error
                }
            ]
        }
    }
}
