import Vue from 'vue';
import {formatDate} from './Utils'
import {seatModelList} from "@/data/index";

const dateCus = function (date, fmt) {
    return formatDate(date, fmt)
}
//价格
const currency = function (price = 0, ratio = 1) {
    if (!price)
        return '0.00'
    let value = price / ratio;  // 系数
    return (value % 1 === 0 ? value + '.00' : value)
};
//获取车座描述
const seatModelDesc = (value) => {
    let ret = seatModelList.filter((item) => value === item.value);
    if (ret.length > 0)
        return ret[0].name
    else return ''
}

//格式化值和描述
export const formatDesc = (value, data, key = "value", name = "label") => {
    return (data.find((item) => item[key] === value) || {})[name]
}


export default {
    install: () => {
        Vue.filter('dateCus', dateCus);
        Vue.filter('currency', currency);
        Vue.filter('seatModelDesc', seatModelDesc)
        Vue.filter('formatDesc', formatDesc)
    }
}
