import {Indicator} from 'mint-ui';

const show = function () {
    Indicator.open();
}

const hide = function () {
    setTimeout(() => {
        Indicator.close()
    }, 100)
}

export default {
    show,
    hide
}
