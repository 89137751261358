import {getChannelConfig} from './apolloConfigData'

//车型车座
export const seatModelList = [
    {name: '按人数分配车型', maxSeats: 52, desc: '', value: 14},
    {name: '5座 经济型', maxSeats: 4, desc: '卡罗拉、荣威ei6、比亚迪唐 等同级别车型', value: 1, wheelbase: 2560},
    {name: '5座 舒适型', maxSeats: 4, desc: '帕萨特、雅阁、凯美瑞、荣威e950 等同级别车型', value: 2, wheelbase: 2850},
    {name: '5座 豪华型', maxSeats: 4, desc: '奥迪A6、宝马5系 等同级别车型', value: 3, wheelbase: 2850},
    {name: '7座 豪华型', maxSeats: 6, desc: '别克GL8、奥德赛 等同级别车型', value: 25, wheelbase: 3088},
    {name: '7座 商务型', maxSeats: 6, desc: '别克GL8、奥德赛 等同级别车型', value: 5, wheelbase: 3088},
    {name: '8座 小巴', maxSeats: 7, desc: '海狮、全顺 等同级别车型', value: 15},
    {name: '9座 小巴', maxSeats: 8, desc: '海狮、全顺 等同级别车型', value: 6},
    {name: '10座 小巴', maxSeats: 9, desc: '海狮、全顺 等同级别车型', value: 16},
    {name: '12座 小巴', maxSeats: 11, desc: '海狮、全顺 等同级别车型', value: 7},
    {name: '14座 中巴', maxSeats: 13, desc: '考斯特、全顺等同级别车型', value: 24},
    {name: '19座 中巴', maxSeats: 18, desc: '考斯特、全顺等同级别车型', value: 8},
    {name: '23座 中巴', maxSeats: 22, desc: '考斯特、全顺等同级别车型', value: 9},
    {name: '25座 中巴', maxSeats: 24, desc: '考斯特、全顺等同级别车型', value: 19},
    {name: '30座 中巴', maxSeats: 29, desc: '考斯特、全顺等同级别车型', value: 20},
    {name: '33座 大巴', maxSeats: 32, desc: '宇通、金龙 等同级别车型', value: 10},
    {name: '35座 大巴', maxSeats: 34, desc: '宇通、金龙 等同级别车型', value: 21},
    {name: '37座 大巴', maxSeats: 36, desc: '宇通、金龙 等同级别车型', value: 11},
    {name: '40座 大巴', maxSeats: 39, desc: '宇通、金龙 等同级别车型', value: 22},
    {name: '43座 大巴', maxSeats: 42, desc: '宇通、金龙 等同级别车型', value: 23},
    {name: '53座 大巴', maxSeats: 52, desc: '宇通、金龙 等z同级别车型', value: 13},
];
//产品类型
export const productFormList = [
    {value: 2, name: '按日包车', useCar: '1', productType: 2},
    {value: 3, name: '线路包车', useCar: '1', productType: 2},
    {value: 4, name: '精致小团', useCar: '0', productType: 3},
    {value: 5, name: '车+X', useCar: '1', productType: 3},
    {value: 6, name: '特色体验', useCar: '1', productType: 3},
    {value: 7, name: '接机', useCar: '1', productType: 1},
    {value: 8, name: '送机', useCar: '1', productType: 1},
    {value: 9, name: '接站', useCar: '1', productType: 1},
    {value: 10, name: '送站', useCar: '1', productType: 1},
    {value: 11, name: '门票', useCar: '0', productType: 3},
    {value: 12, name: '接送服务', useCar: '1', productType: 1},
    {value: 14, name: '即时预约接送服务', useCar: '1', productType: 7},
    {value: 15, name: '即时预约按日包车', useCar: '1', productType: 7}
];
//资源类型
export const resourceTypeList = [
    {value: 2, name: '按日包车', useCar: '1', productType: 2},
    {value: 3, name: '线路包车', useCar: '1', productType: 2},
    {value: 4, name: '精致小团', useCar: '0', productType: 3},
    {value: 5, name: '车+X', useCar: '1', productType: 3},
    {value: 6, name: '特色体验', useCar: '1', productType: 3},
    {value: 7, name: '接机', useCar: '1', productType: 1},
    {value: 8, name: '送机', useCar: '1', productType: 1},
    {value: 9, name: '接站', useCar: '1', productType: 1},
    {value: 10, name: '送站', useCar: '1', productType: 1},
    {value: 11, name: '门票', useCar: '0', productType: 3},
    {value: 12, name: '接送服务', useCar: '1', productType: 1},
    {value: 19, name: '即时预约接送服务', useCar: '1', productType: 7},
    {value: 20, name: '即时预约按日包车', useCar: '1', productType: 7}
];

//订单状态
export const orderStatusList = [
    {name: '新订待分配', value: '21'},
    {name: '新订待确认', value: '22'},
    {name: '供应商拒绝', value: '23'},
    {name: '待分配司导', value: '31'},
    {name: '抢单中', value: '32'},
    {name: '待出行', value: '41'},
    {name: '司机已就位', value: '42'},
    {name: '司机准备中', value: '43'},
    {name: '进行中', value: '51'},
    {name: '已完成', value: '61'},
    {name: '取消中', value: '71'},
    {name: '已取消', value: '72'}
];
//渠道
export const channel = [
    {id: 1, title: '携程'},
    {id: 2, title: '900'},
    {id: 3, title: '上海邮轮码头'},
    {id: 4, title: '尚游'},
    {id: 5, title: '沈阳南航'},
    {id: 6, title: '同城用车'}
]

//获取车型车座
export const getSeatModel = (val) => seatModelList.find(item => parseInt(val) === item.value);

//获取渠道
export const getChannel = getChannelConfig;

// 证件类型
export const certificateList = [
    {type: 1, text: '身份证'},
    {type: 2, text: '港澳通行证'},
    {type: 3, text: '护照（外籍）'},
    {type: 4, text: '军官证'},
    {type: 5, text: '士兵证'},
    {type: 6, text: '台胞证'},
    {type: 7, text: '导游证'}
];
