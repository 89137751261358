import Vue from 'vue';
import App from './App.vue';
import router from '../router/index';
import store from '../vuex/index';
import global from '@/common/js/global';
import filter from '@/common/js/filter';
import VueClipboard from "vue-clipboard2";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


Vue.use(ElementUI)

Vue.config.productionTip = false

Vue.use(filter);
//  复制粘贴板功能
Vue.use(VueClipboard);

global.init(Vue, store);

new Vue({
    el: '#app',
    store,
    router,
    template: '<App/>',
    components: {App}
})
