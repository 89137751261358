
export default {
    direction: 'forward',
    viewTitle: {
        name: '900游管理系统',
        isShowBack: {
            showBack: true,
            preventGoBack: true
        }
    }
}
