export default [
    {
        path: '*',
        redirect: '/home'
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录',
            free: true
        },
        component: r => require.ensure([], () => r(require('../pages/login/')), 'login')
    },
    {
        path: '/forgetPassword',
        name: 'forgetPassword',
        meta: {
            title: '忘记密码',
            free: true
        },
        component: r => require.ensure([], () => r(require('../pages/forgetPassword/')), 'forgetPassword')
    },
    {
        path: '/home',
        name: 'home',
        meta: {
            title: '900游管理系统'
        },
        component: r => require.ensure([], () => r(require('../pages/home/')), 'home')
    }
]
