let Debug = true;//测试 开发模式自由匹配


const MODULE = process.env_project.MODULE_ENV;

const protocol = document.location.protocol;

if (process.env_project.BULID_ENV === 'fat')
    Debug = true;
if (process.env_project.BULID_ENV === 'pro')
    Debug = false;

const port = {
    'bms': 'bms',
    'bms_h5': 'bms',
}

let api_url = `${protocol}//i${port[MODULE]}.900u.xyz`,
    B2B_url = `${protocol}//${port[MODULE]}.900etrip.com`,
    host_cdn = `${protocol}//cdn.900etrip.com/static/${MODULE}/3/`,
    http_url = 'http://server2.900etrip.com/',
    storagePrefix = `${MODULE}_3_`,
    serviceName = {
        "bms": "GALAXY-API-SERVICE",
        "tds": "GALAXY-TDS-SERVICE"
    },
    host_urls = [];

if (Debug) {
    api_url = `${protocol}//i${port[MODULE]}-fat.900u.xyz`;
    B2B_url = `${protocol}//${port[MODULE]}-emu.900etrip.com`;
    http_url = 'http://emu.900etrip.com/';
    storagePrefix += 'dev_';
    host_cdn = "/";
    host_urls = [];

    //本地测试  bms: 4000   tds: 4001
    // api_url="http://192.168.1.12:4000"//bms
    // api_url="http://192.168.1.12:4001"//tds
}
host_cdn += "static/";

export default {
    api_url,
    B2B_url,
    http_url,
    storagePrefix,
    host_urls,
    Debug,
    serviceName,
    host_cdn,
    protocol
}
