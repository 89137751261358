import config from '../../config'

const url = config.api_url + '/galaxyConfig/config/';
export default async (name) => {
    try {
        const response = await fetch(`${url}${name}`);
        if (response.status === 200) {
            const responseJson = await response.json();
            if (responseJson.data)
                return JSON.parse(responseJson.data);
            else
                return false
        }
    } catch (e) {
        return false;
    }
}
