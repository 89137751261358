import Vue from 'vue'
import Vuex from 'vuex'

import cState from '../../common/vuex/state'
import cActions from '../../common/vuex/actions'
import cMutations from '../../common/vuex/mutations'

import state from './state'
import actions from './actions'
import mutations from './mutations'

//  自定义store modules导入

global.hub = new Vue();

Vue.use(Vuex)

Object.assign(state, cState);
Object.assign(mutations, cMutations);
Object.assign(actions, cActions);

const debug = process.env.NODE_ENV !== 'production'

import orderList from './modules/orderList'

export default new Vuex.Store({
    state,
    actions,
    mutations,
    modules: {
        orderList
    },
    trict: debug
    // plugins: debug ? [createLogger()] : []
})
