import cookieStorage from '../js/cookieStorage'
import localStorage from '../js/localStorage'
import {channel} from "../../data/index";

export default {
    direction: 'forward',
    viewTitle: {
        title: '司导端',
        showBack: true,//显示返回
        preventGoBack: false//阻止返回
    },
    userInfo: cookieStorage.getObject("userInfo") || {},
    eTripToken: cookieStorage.get("eTrip_token"),
    loginUserInfo: localStorage.getObject("loginUserInfo") || {},
    loginOrRegister: {
        show: false,
        type: 0,//0登录 1 注册
    },
    location: {
        cityName: "上海市"
    },
    channel
}
