const state = {
    carList: [],
    tripList: [],
    currentType: 0//0 用车订单 1旅游订单
}
const mutations = {
    // 添加tabs
    setOrderList(state, list) {
        if (state.currentType === 0)
            state.carList = list;
        else state.tripList = list;
    },
    changeOrderStatus(state, opt) {
        let list = state.currentType === 0 ? state.carList : state.tripList;
        list.forEach((item) => {
            if (opt.orderId === item.orderId) {
                item.orderStatus = opt.orderStatus;
            }
        })
        if (state.currentType === 0)
            state.carList = list;
        else state.tripList = list;
    },
    changeOrderType(state,currentType) {
        state.currentType = currentType;
    }
}

const actions = {
    setOrderList: ({commit}, list) => {
        commit('setOrderList', list)
    },
    changeOrderStatus: ({commit}, opt) => {
        commit('changeOrderStatus', opt)
    },
    changeOrderType: ({commit}, type) => {
        commit('changeOrderType', type)
    }
}

export default {
    state,
    mutations,
    actions
}
