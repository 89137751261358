/**
 * 检查浏览器类型
 */
export const browserType = () => {
    //取得浏览器的userAgent字符串
    let userAgent = navigator.userAgent;
    let isOpera = userAgent.indexOf("Opera") > -1;
    if (isOpera) {
        return "Opera"
    } //判断是否Opera浏览器
    if (userAgent.indexOf("Firefox") > -1) {
        return "FF";
    } //判断是否Firefox浏览器
    if (userAgent.indexOf("Chrome") > -1) {
        return "Chrome";
    }
    if (userAgent.indexOf("Safari") > -1) {
        return "Safari";
    } //判断是否Safari浏览器
    if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) {
        return "IE";
    } //判断是否IE浏览器
    if (userAgent.indexOf("Trident") > -1) {
        return "Edge";
    } //判断是否Edge浏览器
}
/**
 * 判断是否是移动端打开
 */
export const isMobile = () => {
    const sUserAgent = navigator.userAgent.toLowerCase();
    const bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    const bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    const bIsMidp = sUserAgent.match(/midp/i) == "midp";
    const bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    const bIsUc = sUserAgent.match(/ucweb/i) === "ucweb";
    const bIsAndroid = sUserAgent.match(/android/i) == "android";
    const bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    const bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    return bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM;
}
/**
 * IE浏览器保存本地
 */
export const SaveAs5 = (downURL) => {
    let oPop = window.open(downURL, "", "width=1, height=1, top=5000, left=5000");
    for (; oPop.document.readyState !== "complete";) {
        if (oPop.document.readyState === "complete") break;
    }
    oPop.document.execCommand("SaveAs");
    oPop.close();
}
/**
 * 谷歌，360极速等浏览器下载
 * @param srcUrl
 */
export const download = (srcUrl) => {
    let $a = document.createElement('a');
    $a.setAttribute("target", "_self");
    $a.setAttribute("href", srcUrl);
    $a.click();
}
export const fileDownLoad = (url) => {
    const browserType = browserType();
    if (browserType === "IE" || browserType === "Edge")
        SaveAs5(url); else download(url);
}
/**
 *获取浏览器参数
 * @param name
 * @returns {null}
 */
export const getQueryString = (name) => {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    let r = '';
    let url = '';
    if (window.location.search) {
        url = decodeURI(window.location.search);
        r = url.substr(1).match(reg);
    } else {
        url = decodeURI(window.location.href);
        r = url.substr(window.location.href.indexOf('?')).substr(1).match(reg)
    }
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
}

/**
 *checkbox转换对应0,1模式 数组长度, itemSelect 选中都下表  从1开始
 * @param len
 * @param itemSelect
 * @returns {string}
 */
export const checkStringFun = (len, itemSelect) => {
    let item = new Array(len);
    for (let i = 0; i < len; i++) item[i] = 0;
    for (let j = 0; j < itemSelect.length; j++) {
        item.splice(parseInt(itemSelect[j]) - 1, 1);
        item.splice(parseInt(itemSelect[j]) - 1, 0, 1);
    }
    return item.join('');
}
/**
 * 是否是真实姓名
 */
export const isRealName = function (name) {
    if (!name)
        return false;
    return /[\u4E00-\u9FA5]{2,5}(?:·[\u4E00-\u9FA5]{2,5})*/.test(name.trim())
}
/**
 *@descrition:手机号码段规则
 */
export const isCellphone = (phone) => {
    return /^[1]\d{10}$/.test(phone);
}
/**
 * 校验密格式，至少6位数字、字母组合
 * @param text
 */
export const verifyPwd = function (text) {
    return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,21}$/.test(text);
}
/**
 * 判断输入是否为字母或者数字
 * @param text
 */
export const onlyLettersOrNumber = function (text) {
    return /^[0-9a-zA-Z]*$/g.test(text);
}
/**
 * 只包含数字
 * @param 数字
 */
export const onlyNumber = function (text) {
    return /^[\d]*$/.test(text);
}
/**
 * 校验中文英文数字
 * @param text
 */
export const isSpecialChart = function (text) {
    return /^[a-zA-Z0-9\u4e00-\u9fa5]+$/.test(text);
}
/**
 * 校验中文英文
 * @param text
 */
export const onlyChineseAndLetter = function (text) {
    return /^[a-zA-Z\u4e00-\u9fa5]+$/.test(text);
}
/**
 * 只校验中文
 * @param text
 */
export const onlyChinese = function (text) {
    return /^[\u4e00-\u9fa5]+$/.test(text);
}
//验证身份证号码
export const isIDCardNumber = function (code) {
    if (!code)
        return false;
    code = code.replace(/(^\s*)|(\s*$)/g, "");
    const city = {
        11: "北京",
        12: "天津",
        13: "河北",
        14: "山西",
        15: "内蒙古",
        21: "辽宁",
        22: "吉林",
        23: "黑龙江 ",
        31: "上海",
        32: "江苏",
        33: "浙江",
        34: "安徽",
        35: "福建",
        36: "江西",
        37: "山东",
        41: "河南",
        42: "湖北 ",
        43: "湖南",
        44: "广东",
        45: "广西",
        46: "海南",
        50: "重庆",
        51: "四川",
        52: "贵州",
        53: "云南",
        54: "西藏 ",
        61: "陕西",
        62: "甘肃",
        63: "青海",
        64: "宁夏",
        65: "新疆",
        71: "台湾",
        81: "香港",
        82: "澳门",
        91: "国外 "
    };
    const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
    const parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
    let tip = "", pass = true;

    if (!code || !/^[1-9]\d{5}(19\d{2}|[2-9]\d{3})((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])(\d{4}|\d{3}X)$/i.test(code)) {
        tip = "身份证号格式错误";
        pass = false;
    } else if (!city[code.substr(0, 2)]) {
        tip = "地址编码错误";
        pass = false;
    } else {
        //18位身份证需要验证最后一位校验位
        if (code.length === 18) {
            code = code.split('');
            //∑(ai×Wi)(mod 11)
            //加权因子
            //校验位
            let sum = 0;
            let ai = 0;
            let wi = 0;
            for (let i = 0; i < 17; i++) {
                ai = code[i];
                wi = factor[i];
                sum += ai * wi;
            }
            // let last = parity[sum % 11];
            if (parity[sum % 11] != code[17].toUpperCase()) {
                tip = "校验位错误";
                pass = false;
            }
        }
    }
    console.log(tip);
    return pass;
}
//金额校验
export const onlyAmount = function (text) {
    return /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(text);
}
/**
 * 数组排序
 * @param data 数组
 * @param sortFields 要排序的字段名
 * @param type  排序类型
 * @returns {Array}
 */

export const sortBy = function (data, sortFields, type) {
    let sortList = [],
        sortList1 = [],
        sortList2 = [];
    const by = function (name) {
        return function (o, p) {
            var a, b;
            if (typeof o === "object" && typeof p === "object" && o && p) {
                a = o[name];
                b = p[name];
                if (a === b) {
                    return 0;
                }
                if (typeof a === typeof b) {
                    return a < b ? -1 : 1;
                }
                return typeof a < typeof b ? -1 : 1;
            } else {
                throw ("error");
            }
        }
    }
    if (!data || data.length === 0) return sortList;
    if (sortFields) {
        if (type != 1) {
            data.forEach((item) => {
                if (item[sortFields]) {
                    sortList1.push(item);
                } else {
                    sortList2.push(item);
                }
            })
            sortList1 = sortList1.sort(by(sortFields));
            sortList = sortList1.concat(sortList2)
        } else {
            data.forEach((item) => {
                if (item[sortFields]) {
                    sortList1.push(item);
                } else {
                    sortList2.push(item);
                }
            })
            sortList1 = sortList1.reverse(by(sortFields));
            sortList = sortList1.concat(sortList2)
        }

    } else {
        if (type != 1) sortList = data.sort();
        else sortList = data.reverse();
    }
    return sortList;
}
/**
 * 数组去重
 * @param arr 单数组
 * @returns []去重后的数组
 */
export const uniq = function (arr) {
    return Array.from(new Set(arr))
}
/**
 * JSON数组去重
 * @param: [array] json Array
 * @param: [string] 唯一的key名，根据此键名进行去重
 **/
export const uniqueArray = function (array, key, bool) {
    let result = [array[0]];
    let result1 = [];
    for (let i = 1; i < array.length; i++) {
        let item = array[i];
        let repeat = false;
        for (let j = 0; j < result.length; j++) {
            if (item[key] == result[j][key]) {
                repeat = true;
                break;
            }
        }
        if (!repeat) {
            result.push(item);
        } else {
            result1.push(item);
        }
    }
    let r = result;
    if (bool) r = result1;
    return r;
}
/**
 * 取小数点后两位
 */
export const keepTwoDecimal = function (value, fixed = 2) {
    let num = Number(value);
    if (isNaN(num)) num = 0;
    return num.toFixed(fixed);
}
/**
 * JS格式化手机号（344）
 */
export const formatPhoneNumber = function (value) {
    let str = value.toString().replace(/ /g, '').replace(/[^0-9]/g, '');
    let len = str.length;
    switch (true) {
        case len > 11:
            str = str.substr(0, 3) + ' ' + str.substr(3, 4) + ' ' + str.substr(7, 4);
            value = str;
            break;
        case len > 7:
            str = str.substr(0, 3) + ' ' + str.substr(3, 4) + ' ' + str.substr(7);
            value = str;
            break;
        case len > 3:
            str = str.substr(0, 3) + ' ' + str.substr(3);
            value = str;
            break;
        default:
            value = str;
    }
    return value
}
/**
 * 校验车牌
 *
 */
export const isCarNumberPlate = function (value) {
    return /^[\u4e00-\u9fa5]{1}[A-HJ-NP-Z]{1}[A-HJ-NP-Z0-9]{5,6}$/.test(value);
}
/**
 * 格式化时间
 */
export const formatDate = function (date, fmt='yyyy-MM-dd hh:mm:ss') {
    if (!date)
        return null;
    if (typeof date === "string")
        date = date.replace(/-/g, '/');
    date = new Date(date);
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    }
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + ''
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length))
        }
    }
    return fmt
}
/**
 校验银行卡号
 */
export const isBankcardNo = function (val) {
    return /\d{10,19}/.test(val)
}
