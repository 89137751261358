import types from './mutation-types'

export default {
    [types.CHANGE_LOGIN_OR_REGISTER](state, opt) {
        state.loginOrRegister = opt;
    },
    [types.CHANGE_USER_INFO](state, userInfo) {
        state.userInfo = userInfo
    },
    [types.CHANGE_USER_LOGIN_INFO](state, loginInfo) {
        if (loginInfo && loginInfo.token) {
            state.eTripToken = loginInfo.token;
            state.loginUserInfo = loginInfo;
        }
        else {
            state.eTripToken = null;
            state.loginUserInfo = {};
        }
    },
    [types.CHANGE_LOCATION](state, loaction) {
        state.loaction = loaction
    },
    [types.CHANGE_VIEW_TITLE](state, viewTitle) {
        state.viewTitle = viewTitle
    }
}
