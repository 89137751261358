import Vue from 'vue';
import Router from 'vue-router';
import {beforeEach, afterEach} from '../../common/router/index'
import store from '../vuex'

import routes from './routes';
import order_routes from './order_routes'

Vue.use(Router);

let router = new Router({
    // mode: 'history',
    routes: [].concat(routes, order_routes)
});

router.beforeEach((to, from, next) => {
    beforeEach(to, from, next, store)
});

router.afterEach(() => {
    afterEach()
});

export default router;
