<style lang="scss">
    @import "../../common/style/common.scss";

    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background: #f8f8f8;
        width: 100%;
        height: 100vh;
        /*max-width: 660px;*/
        /*margin: auto;*/
    }
</style>
<template>
    <div id="app">
        <transition name="slide" :duration="0">
            <keep-alive :exclude="['AssignDriver']">
                <router-view class="initial-box"></router-view>
            </keep-alive>
        </transition>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                title: ''
            }
        },
        components: {},
        //计算属性
        computed: {
            // ...mapState({
            //     direction: state => state.direction
            // }),
        },
        mounted: function () {

        },
        methods: {

        },
        watch: {

        }
    }
</script>


